import * as React from "react";
import { FormattedMessage } from "../../localization/index";

const iconEmail = require("../../assets/imgs/icon-email.png");
const iconTel = require("../../assets/imgs/icon-tel.png");
const ccMaster = require("../../assets/imgs/cc-master.png");
const ccVisa = require("../../assets/imgs/cc-visa.png");

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <h1>
          <FormattedMessage
            id="footer-h1"
            defaultMessage="Experience Movio on Any Device"
          ></FormattedMessage>
        </h1>
        <div className="logo-wrap">
          <svg
            className="logo"
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 136.9 51.5"
          >
            <path
              id="m"
              className="cls-1"
              d="M16.8,36.4h.6L22.7,.4h11.1V51.4h-8V14.4h-.1l-5.4,37h-7L7.5,15.4h-.5V51.4H0V.4H11.1l5.7,36Z"
            />
            <path
              id="o"
              className="cls-1"
              d="M39.9,12.7c0-8.1,4.2-12.7,12-12.7s12,4.6,12,12.6v26.2c0,8.1-4.2,12.7-12,12.7s-12-4.6-12-12.6V12.7Zm8,26.3c0,3.5,1.5,4.8,4,4.8s4-1.3,4-4.8V12.5c0-3.5-1.5-4.8-4-4.8s-4,1.3-4,4.8v26.5Z"
            />
            <path
              id="v"
              className="cls-1"
              d="M81.2,42.4h0L87.4,.4h7.3l-7.8,51h-11.9L67.1,.4h8l6.1,42Z"
            />
            <path id="i" className="cls-1" d="M98.9,.4h8V51.4h-8V.4Z" />
            <path
              id="o-2"
              data-name="o"
              className="cls-1"
              d="M112.9,12.7c0-8.1,4.2-12.7,12-12.7s12,4.6,12,12.6v26.2c0,8.1-4.2,12.7-12,12.7s-12-4.6-12-12.6V12.7Zm8,26.3c0,3.5,1.5,4.8,4,4.8s4-1.3,4-4.8V12.5c0-3.5-1.5-4.8-4-4.8s-4,1.3-4,4.8v26.5Z"
            />
          </svg>
        </div>
        <div className="footer-wrap">
          <div className="footer-left">
            <div className="customer-care">
              <h3>
                <FormattedMessage
                  id="customer-care"
                  defaultMessage="Customer Care"
                ></FormattedMessage>
              </h3>
              <div className="list-wrap">
                <div className="each">
                  <div className="icon">
                    <img src={iconTel} />
                  </div>
                  <div className="details">
                    <a href="tel:0805985498">
                      0805 98 5498 (
                      <FormattedMessage
                        id="france"
                        defaultMessage="France"
                      ></FormattedMessage>
                      )
                    </a>
                  </div>
                </div>
                <div className="each">
                  <div className="icon">
                    <img src={iconTel} />
                  </div>
                  <div className="details">
                    <a href="tel:900751051">
                      900 751 051 (
                      <FormattedMessage
                        id="span"
                        defaultMessage="Spain"
                      ></FormattedMessage>
                      )
                    </a>
                  </div>
                </div>
                <div className="each">
                  <div className="icon">
                    <img src={iconEmail} />
                  </div>
                  <div className="details">
                    <a href="mailto:help@moviotv.online">help@moviotv.online</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-right">
            <div className="company-info">
              <p>
                <strong className="text-gr">
                  AMB GLOBAL DIGITAL SOLUTIONS LTD
                </strong>
                <br />
                1 Maiou 3, 2610 Agios Epifanios Oreinis, Cyprus
                <br />
                <a href="https://www.ambglobaldigital.com" target="_blank">
                  www.ambglobaldigital.com
                </a>
              </p>
            </div>
            <div className="copyright">
              {/* <script>document.write(new Date().getFullYear());</script> © Movio <FormattedMessage id="all-rights-reserved" defaultMessage="All Rights Reserved"></FormattedMessage> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="quick-links">
            <a
              className="each"
              href="https://portal.moviotv.online/privacy-policy"
              target="_blank"
            >
              <FormattedMessage
                id="privacy-policy"
                defaultMessage="Privacy Policy"
              ></FormattedMessage>
            </a>
            <a
              className="each"
              href="https://portal.moviotv.online/refund-policy"
              target="_blank"
            >
              <FormattedMessage
                id="refund-policy"
                defaultMessage="Refund Policy"
              ></FormattedMessage>
            </a>
            <a
              className="each"
              href="https://portal.moviotv.online/terms-and-conditions"
              target="_blank"
            >
              <FormattedMessage
                id="terms-conditions"
                defaultMessage="Terms & Conditions"
              ></FormattedMessage>
            </a>
            <a
              className="each"
              href="https://portal.moviotv.online/faq"
              target="_blank"
            >
              <FormattedMessage
                id="faq"
                defaultMessage="FAQ"
              ></FormattedMessage>
            </a>
          </div>
          <div className="credit-card-icon">
            <img src={ccMaster} />
            <img src={ccVisa} />
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
